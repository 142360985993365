import React, { useState } from "react";
import { graphql, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import Layout from "../components/layout";
import Seo from "../components/seo";

import Half8Solid from "../icons/half-8-solid.svg";
import Decoration from "../icons/decoration.svg";

const RichiediDemo = ({ data: { richiedi } }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [error, setError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      privacy: true,
    },
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("contactForm");
    setError(false);
    try {
      const response = await fetch("/api/form-contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          captcha: token,
        }),
      });
      if (!response.ok) {
        throw new Error(`Error submit ${response.status} ${response.statusText}`);
      }
      const result = await response.json();
      console.log("Submit result: ", result);
      navigate("/thankyou/");
    } catch (err) {
      console.error("Error: ", err);
      setError(true);
    }
  };

  return (
    <Layout>
      <Seo
        title={`Intranet 80.20: richiedi la tua demo gratuita`}
        description={`Stai prendendo in considerazione Intranet 80.20? Richiedi la tua demo live personalizzata per scoprire come semplificare la tua comunicazione aziendale!`}
        form={true}
      />

      <div className="hero">
        <div className="container-lg">
          <div className="row">
            <div className="col-12 d-none d-lg-block col-lg-3 offset-lg-1 position-relative">
              <Half8Solid className="ico-svg ico-half-8-solid" />
              <Decoration className="ico-svg ico-hero-decoration ico-bottom" />
              <GatsbyImage
                image={getImage(richiedi)}
                alt="img grafica"
                objectPosition="center center"
                className="d-none d-lg-block mt-3"
              />
            </div>
            <div className="col-12 col-lg-7 offset-lg-1">
              <div className="pb-3">
                <h1 className="hero__title">
                  Richiedi la demo di <strong>Intranet 80.20</strong>
                </h1>
                <p>Verrai contattato al più presto.</p>
                <div className="row mt-5">
                  <div className="col-12">
                    <form onSubmit={handleSubmit(onSubmit)} className="form">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="form__group">
                            <div className="form__wrap-input-text">
                              <input
                                type="text"
                                className="form__input-text"
                                name="nome"
                                id="nome"
                                placeholder=" "
                                {...register("nome", {
                                  required: {
                                    value: true,
                                    message: "Specificare un nome.",
                                  },
                                  pattern: {
                                    value: /^[a-zA-Z\s]*$/,
                                    message: "Specificare un nome (solo caratteri e/o spazi).",
                                  },
                                })}
                                error={errors.nome}
                              />
                              <label className="form__label" htmlFor="nome">
                                Nome e Cognome *
                              </label>
                            </div>
                            {errors.nome ? (
                              <span className="d-block form__error">{errors.nome.message}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form__group">
                            <div className="form__wrap-input-text">
                              <input
                                type="text"
                                className="form__input-text"
                                name="azienda"
                                id="azienda"
                                placeholder=" "
                                {...register("azienda", {
                                  required: {
                                    value: true,
                                    message: "Specificare l'azienda.",
                                  },
                                })}
                                error={errors.azienda}
                              />
                              <label className="form__label" htmlFor="azienda">
                                Azienda *
                              </label>
                            </div>
                            {errors.azienda ? (
                              <span className="d-block form__error">{errors.azienda.message}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form__group">
                            <div className="form__wrap-input-text">
                              <input
                                type="email"
                                className="form__input-text"
                                name="email"
                                id="email"
                                placeholder=" "
                                {...register("email", {
                                  required: {
                                    value: true,
                                    message: "Specificare un indirizzo email.",
                                  },
                                  pattern: {
                                    value:
                                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Specificare un indirizzo email valido.",
                                  },
                                })}
                                error={errors.email}
                              />
                              <label className="form__label" htmlFor="email">
                                Email *
                              </label>
                            </div>
                            {errors.email ? (
                              <span className="d-block form__error">{errors.email.message}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form__group">
                            <div className="form__wrap-input-text">
                              <input
                                type="text"
                                className="form__input-text"
                                name="telefono"
                                id="telefono"
                                placeholder=" "
                                {...register("telefono", {
                                  required: {
                                    value: true,
                                    message: "Specificare un numero di telefono.",
                                  },
                                  pattern: {
                                    value: /^[0-9]*$/,
                                    message: "Specificare un numero di telefono valido.",
                                  },
                                })}
                                error={errors.telefono}
                              />
                              <label className="form__label" htmlFor="telefono">
                                Telefono *
                              </label>
                            </div>
                            {errors.telefono ? (
                              <span className="d-block form__error">{errors.telefono.message}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form__group">
                            <div className="form__wrap-input-text">
                              <textarea
                                type="text"
                                className="form__textarea"
                                name="messaggio"
                                id="messaggio"
                                placeholder=" "
                                {...register("messaggio", {
                                  required: {
                                    value: false,
                                    message: "Specificare un messaggio.",
                                  },
                                })}
                                error={errors.messaggio}
                              />
                              <label className="form__label" htmlFor="messaggio">
                                Messaggio
                              </label>
                            </div>
                            {errors.messaggio ? (
                              <span className="d-block form__error">
                                {errors.messaggio.message}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form__group">
                            <label className="checkbox">
                              <div>
                                Ho letto l'informativa privacy e acconsento alla memorizzazione dei
                                miei dati nel vostro archivio secondo quanto stabilito dal
                                regolamento europeo per la protezione dei dati personali n.
                                679/2016, GDPR.
                              </div>
                              <input
                                type="checkbox"
                                name="privacy"
                                id="privacy"
                                className="checkbox__input"
                                {...register("privacy", {
                                  required: {
                                    value: true,
                                    message: "Confermare la presa visione dell'informativa.",
                                  },
                                })}
                              />
                              <span className="checkbox__mark"></span>
                            </label>
                            {errors.privacy ? (
                              <span className="d-block form__error">{errors.privacy.message}</span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-center">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn-link btn-link--secondary submit-click-tracking-ga"
                              data-ga-id="submit button form demo"
                            >
                              Invia
                            </button>
                          </div>
                          {error && (
                            <div className="text-center mt-3">
                              Attenzione: si è verificato un errore, riprova più tardi.
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RichiediDemo;

export const query = graphql`
  query {
    richiedi: file(relativePath: { eq: "img-pagina-demo-left.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
